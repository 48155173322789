<template>
  <div id="vehicle">
    <div class="colorPad iot-container">
      <div class="header">
         <div>
           <img style="width: 256px;" src="@/assets/icon/iot/Vehicle/defaultcar.png"/>
         </div>
         <div class="times">
             <label class="unit">续航</label><label class="num">89</label><label class="unit">公里</label>
         </div>
         <div class="weight">
            <label class="text">总里程:</label>
            <label class="unit">10000 公里</label>
         </div>
         <!-- <div>
            <div class="btn" :style="{'background-color':'#f33'}">
                <img class="btn-icon" src="@/assets/icon/iot/Alight/moonlight.png"/>
            </div>
         </div> -->
         <div class="btn-primary">
            <label class="text">刷新车辆信息</label>
         </div>
      </div>
      <div class="box">
        <div class="head">控车
        </div>
        <div class="body"  style="display:flex;justify-content: space-evenly;align-items:center;">
          <div style="display:flex; justify-content:center;align-items:center;">
              <div class="btn" :style="{'background-color':'#f33'}" v-on:click="lock()">
                <img class="btn-icon" src="@/assets/icon/iot/Vehicle/lock.png"/>
              </div>
              <span style="flex:1;margin-left:10px;font-weight:600;font-size:16px;">{{t('control.lock')}}</span>
          </div>
          <div style="display:flex; justify-content:center;align-items:center;">
              <div class="btn" :style="{'background-color':'#f33'}" v-on:click="unlock()">
                <img class="btn-icon" src="@/assets/icon/iot/Vehicle/unlock.png"/>
              </div>
              <span style="flex:1;margin-left:10px;font-weight:600;font-size:16px;">{{t('control.unlock')}}</span>
          </div>
        </div>
      </div>
      
      <div class="box">
        <div class="head">车辆定位</div>
        <div class="body" style="display:flex;justifyContent:center;flex-direction:column;">
              <div class="map-container" style="height:200px;">
                <div
                id="map-content"
                class="map-content"
                style="overflow: hidden; position: relative; z-index: 0; background-color: rgb(243, 241, 236); color: rgb(0, 0, 0); text-align: left;"
                ></div>
            </div>
        </div>
      </div>
       <div class="box">
        <div class="head">其他信息</div>
        <div class="body" style="display:flex;justifyContent:center;">
            <button @click="feeding" class="btn-primary">车速</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import CommonService from '../../services/CommonService'
  import { useI18n } from "vue-i18n";
  import { reactive } from 'vue';
  import AMapLoader from '@amap/amap-jsapi-loader';
  export default {
    name: "vehicle",
    computed: {},
    data() {
      return {
        t:useI18n({locale:'en'}).t,
        token:'',
        deviceID:'0',//设备的唯一ID
        userDeviceID:'0',//跟用户绑定之后的ID
      };
    },
    computed:{
    },
    methods: {
      loadMap(){
        AMapLoader.load({
        "key": "002974a5ad2286f46d9561c73e6f95ba",              // 申请好的Web端开发者Key，首次调用 load 时必填
        "version": "1.4.15",   // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        "plugins": ['AMap.ToolBar','AMap.Scale','AMap.OverView','AMap.MapType','AMap.ControlBar'],           // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        "AMapUI": {             // 是否加载 AMapUI，缺省不加载
            "version": '1.1',   // AMapUI 缺省 1.1
            "plugins":[],       // 需要加载的 AMapUI ui插件
        },
        "Loca":{                // 是否加载 Loca， 缺省不加载
            "version": '1.3.2'  // Loca 版本，缺省 1.3.2
        },
      }).then((AMap)=>{
          //初始化地图
          this.map = new AMap.Map("map-content", {
            pitch: 75,
            viewMode: "2D",
            zoom: 20,
            expandZoomRange: true,
            //zooms:[3,20],//地图显示的缩放级别
            center: [116.478935,39.997761], //地图中心点
          });

    
          var scale = new AMap.Scale({
            visible: true
          }),
          toolBar = new AMap.ToolBar({
              visible: true
          }),
          overView = new AMap.OverView({
              visible: true
          });
        
          this.map.addControl(scale);
          this.map.addControl(toolBar);
          this.map.addControl(overView);
          // 添加 3D 罗盘控制
          this.map.addControl(new AMap.ControlBar());
          //事件绑定
          AMap.event.addListener(this.map, "click", function (e) {
            console.log(e);
            //that.activity.Location = e.lnglat.lng + "," + e.lnglat.lat;
          });
          
        }).catch(e => {
          console.log(e);
        })
    },
      goHome: function () {
        this.$router.push({ name: "index" });
      },
      start:function(){
        this.message.confirm('确定要启动车辆吗?',()=>{
          CommonService.SendMessage(this.deviceID,{Topic:'',Payload:{Action:'start',Extra:'1'}});
        },()=>{
            this.message.hide();
        });
      },
      stop:function(){
        this.message.confirm('确定要启动车辆吗?',()=>{
          CommonService.SendMessage(this.deviceID,{Topic:'',Payload:{Action:'start',Extra:'1'}});
        },()=>{
            this.message.hide();
        });
      },
      unlock:function(){
        this.message.confirm('确定要解锁车辆吗?',()=>{
          CommonService.SendMessage(this.deviceID,{Topic:'',Payload:{Action:'unlock',Extra:'1'}});
        },()=>{
            this.message.hide();
        });
      },
      lock:function(){
        this.message.confirm('确定要锁定车辆吗?',()=>{
          CommonService.SendMessage(this.deviceID,{Topic:'',Payload:{Action:'lock',Extra:'1'}});
        },()=>{
            this.message.hide();
        });
      }
    },
    created: function () {},
    mounted: function () {
      if(!this.$route.query.token){
          alert('invalid token');
      }
      if(!this.$route.query.id){
          alert('invalid deviceid');
      }
      this.token=this.$route.query.token;
      //该ID为设备与用户绑定之后的ID,并不是设备ID
      this.userDeviceID=this.$route.query.id;
      //设置token
      this.$store.dispatch('user/setToken',this.token);
      //获取设备状态信息
      CommonService.GetDeviceStatus(this.userDeviceID).then(s=>{
        if(s.Success){
           if(s.Data){
              //返回格式{DeviceID:0,IsOnline:1,Status:''}
              this.deviceID=s.Data.DeviceID;
              this.isOnling=s.Data.IsOnline;
              // if(!s.Data.IsOnline){
              //   alert('设备已离线');
              // }
              // var deviceStatus=s.Data.Status;
              // if(deviceStatus.power){
              //   this.power=deviceStatus.power=='on'?1:0;
              //   CommonService.SendMessage(this.deviceID,{Topic:'',Payload:{Action:'power',Extra:this.power==0?'off':'on'}});
              // }
           }
        }
        else{
          alert(s.Message);
        }
      });
      this.loadMap();
    },
    components: {},
  };
</script>
<i18n>
{
   "zh": {
      "title":{
        "power":"开关",
        "brightness":"亮度",
        "colorTemperature":"色温",
        "color":"颜色"
      },
      "power":{
          on:"开",
          off:"关",
      },
      "control":{
          "lock":"锁车",
          "unlock":"解锁"
      }
  },
  "en": {
    "title":{
      "power":"Power",
      "brightness":"Brightness",
      "colorTemperature":"ColorTemperature",
      "color":"Color"
    },
    "power":{
        on:"On",
        off:"Off",
    },
    "colorTemperature":{
        "sunlight":"Sunlight",
        "moonlight":"Moonlight"
    }
  },
}
</i18n>
<style lang="scss">
.btn{
  border-radius:50%;
  width:40px;
  height:40px;
  display:flex;
  justify-content:center;
  align-items:center;
}
.btn-icon{
  width:24px;height:24px;
}
#feeding {
  background: #fff;
  height: 100vh;
  width: 100vw;
}
.header{
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction: column;
  .times{
      .num{
        color:#fff;
        font-size: 50px;
        padding:0 10px;
      }
      .unit{
        font-size: 16px;
        color:#fff;
      }
  }
  .weight{
     color:#fff;
     font-size: 14px;
     
  }
  .detail{
       margin-top: 10px;
   }
}

.btn-mini{
      margin-top: 10px;
      border-radius: 15px;
      color: #fff;
      background: #ccc;
      font-size: 14px;
      padding:5px 10px;
      min-width: 80px;
      text-align: center;
      border:solid 1px #fff;
}
.btn-primary{
      margin-top: 10px;
      border-radius: 15px;
      color: #fff;
      background: #ff3333;
      font-size: 14px;
      padding:5px 10px;
      min-width: 80px;
      text-align: center;
      border:solid 1px #fff;
}

.map-content {
  min-height: 100px;
  height: 100%;
}


.colorPad {
  background: pink;
  background:-webkit-linear-gradient(#ff3333, #ff00ff, #ff3333);
  background:-o-linear-gradient(#ff3333, #ff00ff, #ff3333);
  background:-moz-linear-gradient(#ff3333, #ff00ff, #ff3333);
  background: linear-gradient(#ff3333, #ff00ff, #ff3333);
  height: 100%;
  overflow: hidden;
  padding-top: 50px;
  .box {
    border-radius: 10px;
    min-height: 100px;
    background: #fff;
    margin: 20px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
  }
  .box .head {
    height: 50px;
    display: flex;
    justify-content: flex-start;
    padding:2px 20px;
    align-items: center;
    border-bottom: #eee 1px solid;
  }
  .box .body {
    display: flex;
    justify-content: flex-start;
    padding:20px 20px 20px 20px;
    flex: 1;
  }
  .box:first-child {
    margin-top: 0;
  }
}
</style>
