import ApiService from './ApiService'
class CommonService extends ApiService {
    getRegions() {
        return super.Get('/api/common/regions');
    }
    SendMessage(deviceID,msg) {
        return super.Post(`/api/device/${deviceID}/sendmessage`,msg);
    }
    // GetUserDevice(userDeviceID) {
    //     return super.Get(`/api/device/userDevice/${deviceID}/`);
    // }
    GetDeviceStatus(userDeviceID) {
        return super.Get(`/api/device/${userDeviceID}/status/`);
    }
}
export default new CommonService()